import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  VideoPlayer,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Eye from '../../assets/images/icons/eye.svg';
import Money from '../../assets/images/icons/money.svg';
import Website from '../../assets/images/icons/website.svg';
import Flexible from '../../assets/images/icons/flexible.svg';
import Touch from '../../assets/images/icons/touch.svg';
import RewardB from '../../assets/images/icons/reward-b.svg';

const Platform = () => {
  return (
    <PageLayout>
      <SEO
        title="Open Source Blockchain Platform"
        description="Dragonchain's open source blockchain platform has unique hybrid flexibility, scalability, and
        security for your business needs"
      />
      <Layout dark background="blue-dark">
        <Hero type="code">
          <div className="caption">Open Source</div>
          <Title color="white">Hybrid Blockchain Platform for Flexibility, Scalability, and Security </Title>
          <Description color="blue-light">
            Integrate applications on top of existing systems that can adapt and scale to your business needs.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Website,
                title: 'Containerized Smart Contracts',
                link: './smart-contracts',
                body:
                  'Deploy smart contracts in any programming language whenever you want, no testnet needed.',
              },
              {
                Icon: Flexible,
                title: 'Docker images',
                body: 'Implement a secure and high-performance blockchain solution using Docker containers.',
              },
              {
                Icon: Eye,
                title: 'Blockchain as a Service',
                body: 'A full-stack public/private hybrid blockchain on a cloud-based or on-premise model.',
              },
              {
                Icon: Money,
                title: 'Affordable High Performance',
                body:
                  'Instant transaction processing, fixed 5-second blocks, deterministic and predictable transaction fees as low as $0.0000025.',
              },
              {
                Icon: RewardB,
                title: 'Scalability',
                link: './scalability',
                body:
                  'Every application operates as its own non-homogeneous private blockchain with selective public exposure capabilities when needed.',
              },
              {
                Icon: Touch,
                title: 'Interoperability',
                body:
                  'Protect sensitive business logic, remain GDPR/HIPAA capable, while allowing interoperability with other blockchains and traditional systems.',
              },
            ]}
          />
          <Row auto span={12} gap="large">
            <Column>
              <Section
                caption="Blockchain as a Service"
                title="A better way to code and develop blockchain applications"
              >
                <p>
                  Innovators are already using the blockchain platform to create value and drive disruption in
                  their industries. Dragonchain’s blockchain platform and blockchain development team can
                  assist you in taking your proof of concepts from the testing stage to successful
                  implementation and live production. Our Blockchain as a Service and software solutions
                  provide everything a business needs to build, operate, govern, and grow a working blockchain
                  network.
                </p>
                <br />
                <p>
                  Your blockchain is private, but has all the flexibility you need for your custom solutions,
                  business goals, and requirements. This architecture, called hybrid blockchain, allows you to
                  utilize the best features from both private and public blockchains without sacrificing
                  security. This provides a scalable way to validate transactions, performed through a
                  decentralized consensus mechanism.
                </p>
              </Section>
            </Column>
            {/* TODO: this needs to be in a column */}
            <VideoPlayer
              url="https://www.youtube.com/watch?v=ef4SF25v9ys"
              caption="Blockchain as a Service at Scale for Enterprise"
            />
          </Row>
          <Row gap="large" /> {/* TODO: remove after video player fix */}
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="Can Dragonchain handle millions of transactions for my business, while remaining affordable?">
                  Dragonchain enables you to run multiple systems to support hundreds of millions or even
                  billions of transactions per day. Transactions are very affordable with our lowest fee being
                  $0.0000025 per transaction. There is no more scalable and affordable alternative without
                  sacrificing security, flexibility, and ease of development.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={7}>
              <Section title="Build your competitive advantage.">
                Dragonchain is blockchain and currency agnostic. Write your own smart contract to a Docker
                container. Push it up, interface with whatever public blockchain you want, and execute your
                own business data within minutes. Let Dragonchain enable you to build the private, secure,
                scalable and cost-effective solutions and services your business deserves, today.
              </Section>
            </Column>
            <Column right middle span={5}>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Platform;
